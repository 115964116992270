import React from 'react';
import { getCategoryTags, translateText } from '../../common/LanguageTexts';
import FooterItem from '../FooterItem/FooterItem';

const DANUBLUE_URL = "https://danublue.com";
const PHI_URL = "https://phi.city";

const DanuBlueItem = () => {
  return <FooterItem href={DANUBLUE_URL} text="DañuBlue" />;
};

const PhiCityItem = () => {
  return <FooterItem href={PHI_URL} text="Phi.City" />;
};

const EnglishItems = () => {
  return (
    <>
      <div className="text-center py-4">
        {translateText('en', 'Topics')}:
        {getCategoryTags('en').map((item, index) => (
          <>
            {' '}
            <FooterItem href={item.link} text={item.name} key={index} />{' '}
            {index < getCategoryTags('en').length - 1 ? '|' : null}
          </>
        ))}
      </div>
      <div className="text-center pb-4">
        {translateText('en', 'Projects')}: <DanuBlueItem /> | <PhiCityItem />
      </div>
      <div className="text-center pb-4">
        <FooterItem href="/en/contact" text="Contact" /> |{' '}
        <FooterItem href="/en/imprint" text="Imprint" /> |{' '}
        <FooterItem href="/fr" text="Pages en français" />
      </div>
    </>
  );
};

const FrenchItems = () => {
  return (
    <>
      <div className="text-center py-4">
        {translateText('fr', 'Topics')}:{' '}
        {getCategoryTags('fr').map((item, index) => (
          <>
            {' '}
            <FooterItem href={item.link} text={item.name} key={index}/>{' '}
            {index < getCategoryTags('fr').length - 1 ? '|' : null}
          </>
        ))}
      </div>
      <div className="text-center pb-4">
        {translateText('fr', 'Projects')}: <DanuBlueItem /> | <PhiCityItem />
      </div>
      <div className="text-center pb-4">
        <FooterItem href="/fr/contact" text="Contact" /> |{' '}
        <FooterItem href="/en/imprint" text="Mentions légales" /> |{' '}
        <FooterItem href="/" text="Pages in English" />
      </div>
    </>
  );
};

const SpanishItems = () => {
  return (
    <>
      <div className="text-center py-4">
        {translateText('es', 'Topics')}:{' '}
        {getCategoryTags('es').map((item, index) => (
          <>
            {' '}
            <FooterItem href={item.link} text={item.name} key={index} />{' '}
            {index < getCategoryTags('es').length - 1 ? '|' : null}
          </>
        ))}
      </div>
      <div className="text-center pb-4">
        {translateText('es', 'Projects')}: <DanuBlueItem /> | <PhiCityItem />
      </div>
      <div className="text-center pb-4">
        <FooterItem href="/es/contacto" text="Contacto" /> |{' '}
        <FooterItem href="/en/imprint" text="Aviso Legal" /> |{' '}
        <FooterItem href="/" text="Pages in English" />
      </div>
    </>
  );
};


const FooterItems = ({ lang }) => {
  switch (lang) {
    case "en": return <EnglishItems />
    case "fr": return <FrenchItems />
    case "es": return <SpanishItems />
    default:
      break;
  }
};

const PostFooter = ({ lang }) => {
  return (
    <footer className="bg-footer text-white w-full">
      <FooterItems lang={lang} />
    </footer>
  );
};

export default PostFooter;

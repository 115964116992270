import { Link } from 'gatsby';
import React from 'react';

const PostHeaderLanguageSelector = ({ url, lang }) => {
  return (
    <div className="hidden md:flex text-lg ml-auto">
      <Link className="text-white hover:text-white hover:underline" to={url}>
        {lang}
      </Link>
    </div>
  );
}

export default PostHeaderLanguageSelector;

import React from 'react';
import { EN_CODE, FR_CODE } from '../../../common/Constants';

const CC_BY_LICENSE = 'cc-by';
const CC_BY_LICENSE_LINK_EN = 'http://creativecommons.org/licenses/by/4.0/';
const CC_BY_LICENSE_LINK_FR = 'https://creativecommons.org/licenses/by/4.0/deed.fr';
const CC_BY_LICENSE_NAME = 'Creative Commons Attribution 4.0 International License';

const SubtitleButton = ({lang}) => {
  if (lang === EN_CODE) {
    return (
      <>
        This work is licensed under a
        {' '}
        <a
          rel="license noreferrer"
          target="_blank"
          href={CC_BY_LICENSE_LINK_EN}
        >
          {CC_BY_LICENSE_NAME}
        </a>
      </>
    );
  }

  if (lang === FR_CODE) {
    return (
      <>
        Cette œuvre est soumise à une licence
        {' '}
        <a
          rel="license noreferrer"
          target="_blank"
          href={CC_BY_LICENSE_LINK_FR}
        >
          {CC_BY_LICENSE_NAME}
        </a>
      </>
    );
  }

  return null;
};

const LicenseButton = ({ license, lang }) => {
  if (license === CC_BY_LICENSE) {
    return (
      <div className="mb-4">
        <br />
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
          <img
            alt="Creative Commons License"
            className="border-0"
            src="https://i.creativecommons.org/l/by/4.0/88x31.png"
          />
        </a>
        <br />
        <SubtitleButton lang={lang} />
        .
      </div>
    );
  }

  return null;
}

export default LicenseButton;

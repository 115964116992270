import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { EN_CODE } from '../common/Constants';
import Layout from '../components/Layout/Layout';
import PostFooter from '../components/PostFooter/PostFooter';
import PostTemplateDetails from '../components/PostTemplateDetails/PostTemplateDetails';

const PostTemplate = (props) => {
  const { title, subtitle } = props.data.site.siteMetadata;
  const post = props.data.markdownRemark;
  const { title: postTitle, description: postDescription } = post.frontmatter;
  const { lang } = post.frontmatter;
  const siteTitle = lang === EN_CODE ? title : 'Réflexions par Thomas Derflinger';
  const description = postDescription !== null ? postDescription : subtitle;
  const { slug } = post.fields;
  const { location } = props;

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <Helmet>
          <script defer data-domain="tderflinger.com" src="https://plausible.io/js/script.js"></script>
          <title>{`${postTitle} - ${siteTitle}`}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={slug} />
        </Helmet>
        <PostTemplateDetails {...props} />
      </Layout>
      <PostFooter lang={lang} />
    </>
  );
}

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author {
          name
          twitter
        }
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        categorySlug
        slug
      }
      frontmatter {
        title
        lang
        path
        pathFr
        pathEn
        license
        category
        attachments {
          childImageSharp {
            gatsbyImageData(
              width: 960
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        tags
        date
        description
      }
    }
  }
`;



import Button from '@mui/material/Button';
import React from 'react';

const StandardButton = ({ onClick, children, className, variant = "contained" }) => {
  return (
    <div className={`mt-4 mb-6 ${className}`}>
      <Button variant={variant} onClick={onClick}>
        {children}
      </Button>
    </div>
  );
}

export default StandardButton;

import { Link, navigate } from 'gatsby';
import React from 'react';
import profilePic from '../../assets/images/thomas-derflinger.png';
import { translateText } from '../../common/LanguageTexts';
import StandardButton from '../Buttons/StandardButton/StandardButton';

const PhotoLinks = () => {
  return (
    <div
      className="self-start justify-self-center"
      style={{ gridColumn: '1/3', gridRow: '1' }}
    >
      <Link to="/">
        <img
          src={profilePic}
          width="100"
          height="100"
          alt="Thomas Derflinger"
        />
      </Link>
    </div>
  );
};

const Intro = ({ lang }) => {
  const clickHandler = () => {
    switch (lang) {
      case 'en':
        navigate('/en/about-me');
        break;
      case 'fr':
        navigate('/fr/sur-moi');
        break;
      case 'es':
        navigate('/es/acerca-mi');
        break;
      default:
        break;
    }
  };

  const contactHandler = () => {
    switch (lang) {
      case 'en':
        navigate('/en/contact');
        break;
      case 'fr':
        navigate('/fr/contact');
        break;
      case 'es':
        navigate('/es/contacto');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <p className="font-bold mt-0 pt-0">
        {translateText(lang, 'AuthorLineWritten')}
      </p>
      <p>{translateText(lang, 'AuthorLineDetails')} </p>
      <p className="flex">
        <StandardButton onClick={contactHandler}>
          {translateText(lang, 'ContactMe')}
        </StandardButton>
        <StandardButton onClick={clickHandler} className="ml-4" variant="outlined">
          {translateText(lang, 'AuthorLineReadMore')}
        </StandardButton>
      </p>
    </>
  );
};

const AuthorBlock = ({ lang }) => {
  return (
    <div className="grid pt-6">
      <PhotoLinks />
      <div
        className="self-center justify-self-start row-span-1 ml-8 mt-0"
        style={{ gridRow: '1' }}
      >
        <Intro lang={lang} />
      </div>
    </div>
  );
};

const AuthorLine = ({ lang }) => {
  return (
    <div className="overflow-hidden border-t-2 border-black mb-2">
      <AuthorBlock lang={lang} />
    </div>
  );
};

export { AuthorLine, PhotoLinks };

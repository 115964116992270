import { Link } from 'gatsby';
import React from 'react';

const FooterItem = ({ href, text }) => {
  return (
    <Link
      className="hover:text-white hover:underline text-white font-bold"
      to={href}
    >
      {text}
    </Link>
  );
}

export default FooterItem;

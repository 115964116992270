import React from 'react';
import {
  EN_CODE,
  FR_CODE
} from '../../common/Constants';
import moment from '../../common/MomentConfiguration';

const PublishedText = ({ lang }) => (lang === FR_CODE ? 'Publié le ' : 'Published ');

const PublishedDate = ({ lang, date }) => (lang === FR_CODE
  ? moment(date)
    .locale(FR_CODE)
    .format('D MMMM YYYY')
  : moment(date)
    .locale(EN_CODE)
    .format('D MMM YYYY'));

const PostPublished = ({ date, lang }) => {
  return (
    <em>
      <PublishedText lang={lang} />
      <PublishedDate lang={lang} date={date} />
    </em>
  );
}

export default PostPublished;

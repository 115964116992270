import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { AuthorLine } from '../AuthorLine/AuthorLine';
import LicenseButton from '../Buttons/LicenseButton/LicenseButton';
import PostCategory from '../PostCategory/PostCategory';
import PostDate from '../PostDate/PostDate';
import PostHeader from '../PostHeader/PostHeader';
import PostPublished from '../PostPublished/PostPublished';
import './style.scss';

const PostHeaderHeader = ({ title }) => {
  return (
    <h1 className="font-extrabold mx-auto text-left max-w-article mb-18">
      {title}
    </h1>
  );
}

const PostTemplateDetails = (props) => {
  const post = props.data.markdownRemark;
  const { lang } = post.frontmatter;
  const { date } = post.frontmatter;
  const { category } = post.frontmatter;
  const { attachments } = post.frontmatter;
  const image = getImage(attachments[0]);

  return (
    <>
      <PostHeader
        lang={lang}
        urlEn={post.frontmatter.pathEn}
        urlFr={post.frontmatter.pathFr}
      />
      <div className="mx-4">
        <div className="max-w-5xl">
          <GatsbyImage
            image={image}
            alt="alt tex"
            className="mt-20 ml-auto mr-auto max-w-full"
            loading="eager"
          />
          <div className="mt-14 max-w-article ml-auto mr-auto">
            <PostDate date={date} lang={post.frontmatter.lang} />
            <PostCategory text={category} slug={post.fields.categorySlug} />
          </div>
          <PostHeaderHeader title={post.frontmatter.title} />
          <div
            className="post-single__body"
            lang={post.frontmatter.lang}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <div className="mx-auto max-w-2xl" style={{ maxWidth: '645px' }}>
            <PostPublished lang={post.frontmatter.lang} date={date} />
            <LicenseButton
              license={post.frontmatter.license}
              lang={post.frontmatter.lang}
            />
          </div>
          <div className="post-single__body">
            <div className="mt-8">
              <AuthorLine
                category={post.frontmatter.category}
                {...props}
                lang={post.frontmatter.lang}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostTemplateDetails;

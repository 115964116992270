import { Link } from 'gatsby';
import React from 'react';
import TDImage from '../../assets/images/thomas-derflinger.png';
import { EN_CODE } from '../../common/Constants';
import { translateText } from '../../common/LanguageTexts';

const getHomeLink = (lang) => {
  if (lang === EN_CODE) {
    return '/';
  } else {
    return `/${lang}`;
  }
};

const getBlogName = (lang) => {
  return translateText(lang, 'BlogName');
};

const PostHeaderPhoto = ({ lang }) => {
  const homeLink = getHomeLink(lang);
  const blogName = getBlogName(lang);

  return (
    <div className="flex mt-0 font-bold ml-4 md:mr-36 md:text-xl">
      <Link
        className="text-white hover:text-white hover:underline"
        to={homeLink}
      >
        <img
          className="mr-4 rounded-lg inline-block"
          src={TDImage}
          alt="Thomas Derflinger"
          width={30}
        />
        {blogName}
      </Link>
    </div>
  );
}

export default PostHeaderPhoto;

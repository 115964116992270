import React from 'react';
import {
  EN_CODE,
  EN_LANGUAGE,
  FR_CODE,
  FR_LANGUAGE
} from '../../common/Constants';
import { getHeaderMenu } from '../../common/LanguageTexts';
import PostHeaderLanguageSelector from '../PostHeaderLanguageSelector/PostHeaderLanguageSelector';
import PostHeaderMenuItem from '../PostHeaderMenuItem/PostHeaderMenuItem';
import PostHeaderPhoto from '../PostHeaderPhoto/PostHeaderPhoto';

const getLanguageVersion = (lang, urlEn, urlFr) => {
  if (lang === EN_CODE) {
    if (urlFr) {
      return FR_LANGUAGE;
    }
  }

  if (lang === FR_CODE) {
    if (urlEn) {
      return EN_LANGUAGE;
    }
  }

  return null;
};

const getMenu = (lang) => {
  return (
    <>
      {getHeaderMenu(lang).map((item) => (
        <PostHeaderMenuItem
          href={item.href}
          text={item.text}
          border={item.border}
        />
      ))}
    </>
  );
};

const PostHeader = ({ lang, urlEn, urlFr }) => {
  const languageSwitcher = getLanguageVersion(lang, urlEn, urlFr);
  const menu = getMenu(lang);
  const languageSwitcherComponent = languageSwitcher ? (
    <PostHeaderLanguageSelector lang={languageSwitcher} url={urlFr || urlEn} />
  ) : null;

  return (
    <div className="fixed flex bg-header text-white left-0 top-0 z-50 w-full p-2 mb-10">
      <PostHeaderPhoto lang={lang} />
      <div className="hidden md:flex text-lg">{menu}</div>
      {languageSwitcherComponent}
    </div>
  );
};

export default PostHeader;

import { Link } from 'gatsby';
import React from 'react';

const PostHeaderMenuItem = ({ href, text, border }) => {
  const withBorder = border ? 'border-white border-2 border-solid p-1 rounded-md' : null;

  return (
    <div className="mr-8">
      <Link className={`text-white hover:text-white hover:underline ${withBorder}`} to={href}>
        {text}
      </Link>
    </div>
  );
}

export default PostHeaderMenuItem;
